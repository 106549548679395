import React, { Component ,Fragment } from "react";
import { GiHamburgerMenu, GiPlatform } from "react-icons/gi";
import { FiCheck} from "react-icons/fi";



class PlataformaAWS extends Component{
    render(){
        return(
            <Fragment>

  {/* Start About Area */}
  <div className="about-area ptb--90 bg_color--5" id="platform">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                <div data-aos="fade-up" className="thumb position-relative m-auto" style={{maxWidth: "500px"}}>
                                        <img style={{boxShadow: "#32074061 8px 6px 15px 3px"}} className="w-100" src={!this.props.img ? "/assets/images/dojopy/plataforma_bootcamp.webp": this.props.img} alt="plataforma_bootcamp"/>
                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen0} videoId='-_IVPd1ryvI' onClose={() => this.setState({isOpen0: false})} /> */}
                                        {/* <button className="video-popup position-top-center" onClick={this.openModal0}><span className="play-icon"></span></button> */}
                                    </div>
                                </div>

                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                       
                                            <h2 className="title mb-0">PLATAFORMA</h2>
                                            <div style={{width: window.type_device ? "200px": "375px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                <p>
                Todos los estudiantes e instructores cuentan con acceso a una plataforma donde encuentran los materiales,
                 comunidad, calendario y los enlaces de las sesiones por Zoom,
                 además las clases quedan grabadas en la plataforma para que puedas verlas las veces que quieras
                con acceso ilimitado. <br/>
                <b>
                La plataforma es impulsada por Inteligencia Artificial, lo que significa que recibirás lecciones y retroalimentacion personalizadas según tus progresos.
                </b>
        </p>
                                       
                                        </div>
                                   

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


            </Fragment>
        )
    }
}




export default PlataformaAWS;