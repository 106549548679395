import React, { Component } from "react";
import { GoRocket } from "react-icons/go";
import { AiFillCalendar } from "react-icons/ai";
import { BiDockBottom, BiSolidPhoneCall } from "react-icons/bi";
import { SiZoom } from "react-icons/si";
import { BsCalendarCheck, BsChatDots } from "react-icons/bs";




class FooterFloat extends Component{


    RenderPc (price,  countryCode, isPricePais, slug) {
        return (
            <div className="row dojofoter" style={{
                marginTop: !window.type_device ? "3px" : "2px",
                marginBottom: !window.type_device ? "3px": "3px",
                paddingLeft: !window.type_device ? "250px" : "15px",
                paddingRight: !window.type_device ? "250px": "15px",
                paddingTop: !window.type_device ? "7px": "3px",
                paddingBottom: !window.type_device ? "7px": "3px"
                }}>

{/*     
                <div className={window.type_device ? "col-6 px-0": "col-4 px-0"}  style={{alignSelf: "center"}}>
             
                    <div style={{placeContent: "left"}}>
                        <div style={{marginTop: "10px", textAlign: "center"}} onClick={() => this.props.callSyllabus()} >
                        <a  href="#syllabus" style={{fontSize: "17px", color: "white", padding: "8px 20px" }}
                        className="btn-efect d-inline-block" >
                        <BiDockBottom style={{fontSize: "17px", marginRight: "5px"}}/>  
                        {window.type_device ? "Temario" : "Descargar Temario"}
                        </a>
                    </div>     
                    
                    </div>

                </div> */}



                {/* <div className={window.type_device ? "col-6 px-0": "col-4 px-0"}  style={{alignSelf: "center"}}>

                <div style={{marginTop: "10px", textAlign: "center"}} onClick={() => window.Calendly.initPopupWidget({url: 'https://calendly.com/dojofullstack/agendar'}) } >
                        <a style={{fontSize: "17px", color: "white", padding: "8px 20px" }} className="btn-efect d-inline-block" href='#agendar' >
                        <BiSolidPhoneCall style={{fontSize: "19px", marginRight: "5px"}}/>
                        {window.type_device ? "Sesión demostrativa" : "Sesión demostrativa "}
                        </a>
                    </div>
                </div> */}


                {/* <div className={window.type_device ? "col-6 px-0": "col-3 px-0"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
                     <div style={{marginTop: "10px", textAlign: "center"}}>
                        <a style={{fontSize: "17px", color: "white", padding: "8px 20px" }}
                        className="btn-efect d-inline-block" href='#fechas' >
                        <BsCalendarCheck style={{fontSize: "16px", marginRight: "5px"}}/>  
                        <>{!this.props.langUS ? "Fechas" : "Start dates"}</>
                        </a>
                    </div>     
                </div> */}



                <div className={"col-12 px-0"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
                    
                {/* {this.props.IsEspecial24h &&
                    <div style={{textAlignLast: "right"}}>
                        <FlipCountdown
                            theme="light"
                            size='small'
                            hourTitle='Horas'
                            minuteTitle='Min'
                            secondTitle='Segs'
                            hideYear
                            hideMonth
                            hideDay
                            endAt={this.props?.FechaEspecial24h}
                            onTimeUp={() => window.location.href = '/'}
                            endAtZero={true}
                            />
                    </div>
                } */}

                    <div style={{marginTop: "5px", textAlign: "center"}}>
                        <a style={{fontSize: "19px", color: "white", padding: window.type_device ? "8px 30px" : "8px 17px" }} className="btn-efect d-inline-block" href={'#fechas'} >
                        <GoRocket style={{fontSize: "18px", marginRight: "5px"}}/>
                        Empezar inscripción
                        </a>
                    </div>

                </div>
    

           

            </div> 
        )
    }


    
    render(){
        return(
        <div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
           {/* linear-gradient(to right, slateblue, #280211) */}
            {/* <p style={{fontWeight: "bold", paddingLeft: "10px", color: "black", marginBottom: "1px", textAlign: window.type_device ? "left": "center"}}>
                El precio de Año Nuevo ya casi termina 
            </p> */}
            
            {this.RenderPc(this.props.price,  this.props.countryCode, this.props.isPricePais, this.props.slug)}

        </div>
        )
    }
}


export default FooterFloat;