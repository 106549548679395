import React, { useState } from 'react';



const FooterTwoFunnel = ({langUS=false}) => {

    return (
        <div style={{marginTop: "20px"}}>
           
                <div className="footer-style-2 "  style={{background: "rgb(27, 18, 18)"}}>

           <div className="wrapper plr--50 plr_sm--10" style={{paddingBottom: window.type_device ? "1px": "1px" }} >
           
               <div className="row align-items-center justify-content-between">
                   
                   <div className="col-12" style={{marginBottom: window.type_device ? "10px": "1px" }}>
                       <div className="inner text-center mt_sm--10">
                           <div className="text-center">
                               <p>Copyright © 2024 DojoFullStack - <br /> {!langUS ? "Todos los derechos reservados.": "All rights reserved."} </p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>

        </div>
        
       
    )
}
export default FooterTwoFunnel;
