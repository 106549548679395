import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class PoliceReembolso extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          username: window.location.pathname.split('/')[1],
        }
        this.openModal = this.openModal.bind(this);

    }

    openModal () {
        this.setState({isOpen: true})
    }

    render(){

        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Políticas de Reembolso' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h3 style={{color: 'white'}} className="title">
                Políticas de Reembolso
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}

                                        <div className="row sercice-details-content pb--80 align-items-center">
                                           
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                   <body class="c11"><h2 class="c8" id="h.laof9t6et3v3"><span class="c9">Política de Reembolso</span></h2><p class="c6"><span class="c0">¡Gracias por adquirir nuestros servicios!</span></p><p class="c6"><span class="c0">
                                                   En DojoFullStack, queremos asegurarnos de que todos nuestros miembros estén satisfechos con su experiencia de aprendizaje.
                                                   Si en algún momento sientes que este programa no es adecuado para ti, nos gustaría escuchar tus comentarios y ayudarte en lo que sea posible.

Si has asistido a las sesiones en vivo y decides que este programa no es lo que necesitas dentro de los primeros 7 días desde la fecha de inicio <b>(Máximo 2 sesiones en vivo emitidas) </b> , te ofrecemos un reembolso completo.
                                                          este se ejecuta posterior a los 30 a 60 días de solicitarlo.
                                                           </span></p><p class="c6"><span class="c1">Elegibilidad para reembolsos y cambios</span></p><ul class="c4 lst-kix_gf8tfl1o4ruu-0 start"><li class="c5 li-bullet-0"><span class="c0">Para completar tu devolución, requerimos un recibo o comprobante de compra de pago.</span></li>
                                                           <li class="c5 li-bullet-0"><span class="c0">Para completar tu devolución requerimos un documento adjunto con las razones o causas de la devolución explicadas.</span></li>
                                                           <li class="c5 li-bullet-0"><span class="c0">
                                                           Nos esforzamos por procesar tu solicitud de reembolso de manera oportuna. <br/>
                                                           Nuestro equipo evaluará cuidadosamente el caso en un plazo aproximado de 3 a 5 días hábiles y tomará una decisión basada en las causas que se han especificado. <br/> Estamos comprometidos en encontrar una solución justa y satisfactoria para todos nuestros miembros.
                                                            </span></li>
                                                           
                                                            </ul>
                                                            
                                                            <p class="c7"><span class="c0"></span></p><p class="c6 c10"><span class="c0"></span></p><p class="c6"><span class="c1">Reembolsos atrasados o faltantes</span></p><ul class="c4 lst-kix_h5bn97qliijk-0 start"><li class="c5 li-bullet-0"><span class="c0">Si aún no has recibido un reembolso, primero verifica otra vez tu cuenta bancaria. Luego, comunícate con la compañía de tu tarjeta de crédito. Puede tomar algún tiempo antes de que se publique oficialmente tu reembolso (En promedio 30 a 60 días).</span></li><li class="c5 li-bullet-0"><span class="c3">Si has hecho todo esto y aún no has recibido tu reembolso, comunícate con nosotros al correo hola@dojofullstack.com</span></li></ul>
                                                            
                                                            </body>
                                                <br/>
                                                </div>
                                            </div>

                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
          
                {/* End Back To Top */}


                <FooterTwo />

            </React.Fragment>
        )
    }

    // async componentDidMount() {
    //    console.log(this.state.username)
    //   }
}
export default PoliceReembolso;
