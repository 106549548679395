const data = [
    {
      title: "HTML",
      background: "url('/assets/images/stack/1.webp')",
    },
    {
      title: "CSS",
      background: "url('/assets/images/stack/2.webp')",
    },
    {
      title: "SASS",
      background: "url('/assets/images/stack/3.webp')",
    },
    {
      title: "JavaScript",
      background: "url('/assets/images/stack/4.webp')",
    },
    {
      title: "Bootstrap",
      background: "url('/assets/images/stack/5.webp')",
    },
    {
      title: "React JS",
      background: "url('/assets/images/stack/6.webp')",
    },
    {
      title: "Python",
      background: "url('/assets/images/stack/8.webp')",
    },
    {
      title: "Django",
      background: "url('/assets/images/stack/19.webp')",
    },
    {
      title: "JWT",
      background: "url('/assets/images/stack/jwt.webp')",
    },
    {
      title: "Studio Code",
      background: "url('/assets/images/stack/9.webp')",
    },
    {
      title: "Github",
      background: "url('/assets/images/stack/10.webp')",
    },
    {
      title: "Git",
      background: "url('/assets/images/stack/11.webp')",
    },
    {
      title: "API",
      background: "url('/assets/images/stack/12.webp')",
    },
    {
      title: "JSON",
      background: "url('/assets/images/stack/13.webp')",
    },
    {
      title: "Terminal",
      background: "url('/assets/images/stack/14.webp')",
    },
    {
      title: "Postgresql",
      background: "url('/assets/images/stack/15.webp')",
    },
    {
      title: "MySql",
      background: "url('/assets/images/stack/16.webp')",
    },
    {
      title: "Postman",
      background: "url('/assets/images/stack/17.webp')",
    },
    {
      title: "NPM",
      background: "url('/assets/images/stack/18.webp')",
    },
    {
      title: "Serverless",
      background: "url('/assets/images/stack/20.webp')",
    },
    {
      title: "FireBase",
      background: "url('/assets/images/stack/21.webp')",
    },
    {
      title: "AWS",
      background: "url('/assets/images/stack/23.webp')",
    }
  ];
  
  export default data;
  