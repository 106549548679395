import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>

             
<div class="row mt-5 mb-3">
                    <div class="col-lg-12 col-12 px-0" >
                        <div class="section-title service-style--3 mb--25 mb_sm--0 text-center">
                            <h3 class="title text-dark">
                            NUESTROS GRADUADOS TRABAJAN EN CONOCIDAS EMPRESAS INTERNACIONALES
                             </h3>

                             <div className="d-flex justify-content-center">
                              <img className="col-12 col-md-6 w-100 img-fluid px-0" src="/assets/images/dojopy/partners-job.png" alt="" srcset="" />
                             </div>
                        </div>
                    </div>
  </div>



                {/* <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/google.webp" alt="google"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/aws.webp" alt="aws"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/airbnb.webp" alt="airbnb"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/ml.webp" alt="essence"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/telefonica.webp" alt="telefnocia"/>
                    </li>
                </ul> */}
            </React.Fragment>
        )
    }
}
export default BrandTwo;
