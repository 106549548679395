const data = [
    {
      title: "Python",
      background: "url('/assets/images/stack/8.webp')",
    },
    {
      title: "Django Framework",
      background: "url('/assets/images/stack/19.webp')",
    },
    {
      title: "Studio Code",
      background: "url('/assets/images/stack/9.webp')",
    },
    {
      title: "Github",
      background: "url('/assets/images/stack/10.webp')",
    },
    {
      title: "Git",
      background: "url('/assets/images/stack/11.webp')",
    },
    {
      title: "API",
      background: "url('/assets/images/stack/12.webp')",
    },
    {
      title: "JSON",
      background: "url('/assets/images/stack/13.webp')",
    },
    {
      title: "Terminal",
      background: "url('/assets/images/stack/14.webp')",
    },
    {
      title: "Postman",
      background: "url('/assets/images/stack/17.webp')",
    },
    {
      title: "Serverless",
      background: "url('/assets/images/stack/20.webp')",
    },
    {
      title: "FireBase",
      background: "url('/assets/images/stack/21.webp')",
    },
    {
      title: "AWS",
      background: "url('/assets/images/stack/23.webp')",
    }
  ];
  
  export default data;
  