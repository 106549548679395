import React, { Component } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
// import Pagination from "../elements/common/Pagination";
// import BlogList from "../elements/blog/BlogList";
// import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
// import BlogContent from "../elements/blog/BlogContent";
import axios from "axios";
import { FaRegEye } from "react-icons/fa";
import Moment from 'moment';
import 'moment/locale/es';  

Moment.locale('es');


class Blog extends Component{
    constructor () {
        super()
        this.state = {
            Posts: []
        }
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    
    render(){

        return(
            <React.Fragment>

                <Helmet>
                    <title>Blog DojoFullStack Full Stack | Bootcamp</title>
                    <meta name="description" content="¿Quieres ser un desarrollador Full Stack? DojoFullStack te ofrece un Bootcamp en vivo y en línea para que aprendas HTML, CSS, JavaScript, React, Python y AWS. Lee nuestro blog para conocer más sobre este apasionante mundo de la programación."  />
                    <meta
                        property="og:description"
                        content="¿Quieres ser un desarrollador Full Stack? DojoFullStack te ofrece un Bootcamp en vivo y en línea para que aprendas HTML, CSS, JavaScript, React, Python y AWS. Lee nuestro blog para conocer más sobre este apasionante mundo de la programación."
                    />
                   <meta
                    name="twitter:description"
                    content="¿Quieres ser un desarrollador Full Stack? DojoFullStack te ofrece un Bootcamp en vivo y en línea para que aprendas HTML, CSS, JavaScript, React, Python y AWS. Lee nuestro blog para conocer más sobre este apasionante mundo de la programación." 
                    />
                    <meta name="keywords" content="Desarrollo web full stack, Bootcamp de programación, HTML, CSS, JavaScript, React, Python y AWS" />
                    <meta property="og:title" content="Blog DojoFullStack | Bootcamp" />
                    <link rel="canonical" href="https://dojofullstack.com/blog" />

                </Helmet>

                <Header headertransparent="header--transparent" colorblack="color--black" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'¡Conoce nuestro Blog!'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--50 bg_color--1">
                    <div className="container">

                    <div className="row">
                    {this.state.Posts.map((value , i ) => (
                        <div style={{cursor: "pointer", marginBottom: "25px"}} className="col-lg-4 col-md-6 col-sm-6 col-12" key={i} onClick={() => window.location = `/blog/${value.slug}` } >
                            <div className="blog blog-style--1 mb-1" >
                                <span style={{color: 'white', zIndex: 10, right: '8px', position: 'absolute', top: '8px', fontWeight: 'bold', fontSize: "15px"}} >
                                    <FaRegEye style={{fontSize: "20px"}} /> {value.counter_views}
                                </span>
                                <div className="thumbnail">
                                    <a href={`/blog/${value.slug}`}>
                                        <img className="w-100" src={value.image_medium} alt={value.title} />
                                    </a>
                                </div>
                                <div className="content">
                                    <h4 className="title"> <a  style={{ fontSize: window.type_device ? "19px" : "23px" }} href={`/blog/${value.slug}`}>{value.title}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white">Ver articulo</a>
                                    </div>
                                </div>
                            </div>
                            <div class="testimonial-thumbnai d-flex mt-2">
                                <div class="thumb" ><img style={{borderRadius: "50%"}} height="45px" src='/assets/images/dojopy/henry_face.webp' alt="Autor Henry"/></div>
                                <div style={{fontSize: "16px", marginLeft: "15px"}}>
                                <div> <span style={{fontWeight: 600}}>Henry</span> </div>
                                <div> <span>{Moment(value.date_created).format('dddd DD MMMM YYYY')} · {value.time_read}</span>
                                 </div>
                                </div>
                            </div>
                        </div>
                     ))}
                 </div>

                    </div>
                </div>
                {/* End Blog Area */}
                
         
                
            <FooterTwo/>
            </React.Fragment>
        )
    }

    async componentDidMount() {

        const response = await axios.get("https://api2.dojofullstack.com/api/blog/list");
        this.setState({Posts: response.data.data});

        this.sleep(1000).then(r => {
            document.getElementById('whatsapp').style.display='block';
        }
            )

    }
}
export default Blog;
