
import {
    Accordion,
    AccordionItem as AccordionItemZero,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import React, { Component ,Fragment } from "react";



class Preguntas extends Component{
    render(){
        let bootcampName = 'Desarrollador Web Full Stack';
        if (this.props.bootcampSlug === 'fullstack-web-apps-completo'){
            bootcampName = 'Desarrollador Full Stack Web y Apps Móviles';
        } else if (this.props.bootcampSlug === 'frontend-completo' ){
            bootcampName = 'Desarrollador Frontend con React JS';
        } else if (this.props.bootcampSlug === 'backend-completo' ){
            bootcampName = 'Desarrollador Backend con Python';
        } else if (this.props.bootcampSlug === 'apps-moviles-flutter' ) {
            bootcampName = '';
        } else if (this.props.bootcampSlug === 'cloud-aws' ) {
            bootcampName = 'Desarrollador Cloud AWS';        
        } else {
            bootcampName = 'Desarrollador Web Full Stack';
        }

        return(
            <Fragment>
 <div className="row" id="preguntas-frecuentes">
        <div className="col-12">
            <div  className=" text-center mb--30" style={{marginRight: window.type_device ? "10px": "50px", marginLeft: window.type_device ? "10px": "50px" }}>
                <br/>
                <img height="70px" className="ayudar" src="/assets/images/featured/ayudar.webp" alt="ayudar"/>

                <h2 className="title mb-4 mt-4">{!this.props.langUS ? "Preguntas Frecuentes" : "Frequent questions"} </h2>

                <Accordion  className="accodion-style--1" preExpanded={''}>

<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "¿Cómo es la metodología online en vivo?" : "How is the live online methodology?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    En este bootcamp online en vivo tendrás interacción instantánea con tu mentor y compañeros, <br/>
 para resolver todas tus dudas y avanzar en los proyectos. <br/>
 El Mentor como el alumno, deben conectarse ciertos días y horarios de la semana.
</> : <>
In this live online bootcamp you will have instant interaction with your mentor and peers, <br/>
  to solve all your doubts and advance in the projects. <br/>
  The Mentor, like the student, must connect on certain days and times of the week.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Hay algún requisito o necesito conocimientos previos? " : "Are there any requirements or do I need prior knowledge?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.<br/>
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.

</> : <>
Don't worry. Our programs are suitable for beginners and do not require any previous knowledge and there is no age limit.<br/>
Plus, you won't be alone. Our team will be by your side to guide you through the process, help you set goals and help you succeed.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "Si falto a una clase ¿puedo recuperarla?" : "If I miss a class, can I make it up?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? <>
    ¡Por supuesto! Las sesiones son online en vivo, pero las sesiones quedan grabadas en nuestra plataforma <br/>
para que puedas verlas las veces que quieras, ademas tendrás acceso para siempre a la plataforma incluso
cuando finalice el BootCamp para que siga reforzando sus habilidades obtenidas.
También ofrecemos la opción de completar el bootcamp a tu propio ritmo en la plataforma.
</> : <>
Of course! The sessions are live online, but the sessions are recorded on our platform <br/>
so that you can see them as many times as you want, you will also have access to the platform forever even
when BootCamp is over so you can continue to build on your earned skills.
</>}

</p>
</AccordionItemPanel>
</AccordionItemZero>

<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
  {!this.props.langUS ? "¿Otorgan certificados?" : "Do you award certificates?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
Al finalizar el evento, todos los participantes recibirán el certificado digital con reconocimiento internacional. Contamos con el respaldo y aval de múltiples empresas en toda LATAM.


</p>
</AccordionItemPanel>
</AccordionItemZero>









<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
¿Cuál es el costo?
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>

    El costo único de la inscripción es de $27 USD.

</p>
</AccordionItemPanel>
</AccordionItemZero>



<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Dónde se realiza el pago de la inscripción? " : "Where is the registration payment made?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>

{!this.props.langUS ? <>
    Puedes pagar directamente desde el sitio web 100% seguro, aquí: <br/>
<a href="#registro" style={{color: "purple"}}>
Empezar inscripción
</a> 
</> : <>
You can pay directly from the 100% secure website, here: <br/>
<a href="#empezar" style={{color: "purple"}}>
start registration
</a> <br/>
Also through WhatsApp No.:
(+51) 935 489 552
<br/>
<a target="blank" href="https://wa.me/51935489552?text=Hi%20DojoFullStack" style={{color: "purple"}}>
Registration by WhatsApp
</a>
</>}



<br/>
</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "¿Métodos de pago?" : "Payment methods?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "Formas de Pago con tarjeta de  débito o crédito, PayPal, Link de Pago." :
 "Payment methods with debit or credit card, transfer or account deposit, PayPal, Payment Link, Virtual POS."}

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
{!this.props.langUS ? "¿Cuándo inicia el Evento?" : "When does the BootCamp start?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<p style={{color:'black', cursor: "pointer"}}>
	
{this.props.dia} <br/> (3 clases en vivo)

</p>
</AccordionItemPanel>
</AccordionItemZero>


<AccordionItemZero>
<AccordionItemHeading>
<AccordionItemButton>
<h3 style={{color:'black', cursor: "pointer"}}>
 {!this.props.langUS ? "¿Qué es DojoFullStack?" : "What is DojoFullStack?"}
</h3>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<>

<>
    <p style={{color:'black', cursor: "pointer"}} >
🚀 Una startup educativa dedicada al desarrollo web Full Stack.
</p>
<p style={{color:'black', cursor: "pointer"}} >
🌏 Accesible desde cualquier lugar del mundo.
</p>
<p style={{color:'black', cursor: "pointer"}} >
💻 Clases 100% online y en vivo.
</p>
</>

</>

</AccordionItemPanel>
</AccordionItemZero>

</Accordion>


            </div>

        </div>
    </div>


            </Fragment>
        )
    }
}




export default Preguntas;