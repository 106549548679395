import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
// import InfoFooter from "./InfoFooter";


class CertifcateDojopy extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          username: window.location.pathname.split('/')[1],
        }
        this.openModal = this.openModal.bind(this);

    }

    openModal () {
        this.setState({isOpen: true})
    }

    render(){

        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Certificado Digital' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--10 pb--190 bg_image bg_image--1"  data-black-overlay="1" style={{paddingBottom: "35px", paddingTop: "35px"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h3 style={{color: 'white'}} className="title">
                                    Certificado Digital Bootcamp
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details pt-3 bg_color--1" style={{padding: "1px", textAlign: "center"}}>
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        
                                        <iframe
                                        src={`https://d352y0uj302yx3.cloudfront.net/web/viewer.html?file=https://bootcamp-fullstack-dojopy.s3.amazonaws.com/certicado/fullstack/${this.state.username}.pdf`}
                                         style={{"width": window.type_device ? "100%": "990px", "height":"700px"}} frameborder="0"></iframe>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
             
                {/* End Back To Top */}


                <FooterTwo />

            </React.Fragment>
        )
    }

    // async componentDidMount() {
    //    console.log(this.state.username)
    //   }
}
export default CertifcateDojopy;
