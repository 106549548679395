import React, { Component } from 'react';


class error404 extends Component {

    render() {
        return (
            <>
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h3 className="sub-title">Página no encontrada</h3>
                                    <br/>
                                    <div className="error-button">
                                        <a className="rn-button-style--2 btn-solid" href="/">Ir al Inicio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

            </>
        )
    }
}
export default error404;
