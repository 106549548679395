import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcPodiumWithSpeaker } from "react-icons/fc";

const TestimonialOne = ({langUS=false}) => {
    return (
        <div className="row">

            <div className="col-12">

                <Tabs>
                   

                <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>
                                Gracias al bootcamp Full Stack Web, conseguí un puesto en una destacada empresa tecnológica. Mis ingresos han crecido hasta $2000 dólares al mes y estoy entusiasmado por todo lo que está por venir ¡Este es solo el inicio de mi carrera!
                                     </p>
                            </div>
                            <div className="author-info">
                            {!langUS ? <h6><span>Carlos</span> </h6>:  <h6><span>Michael</span> - from United States </h6>}
                               
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>
                                El conocimiento y buenas prácticas que dan a conocer los instructores a través de la experiencia, ¡me encantó 100% recomendado!
                                    </p>
                            </div>
                            <div className="author-info">
                                {!langUS ? <h6><span>Luciana</span>  </h6>:  <h6><span>Emily</span> - from United States </h6>}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                            <p>
                            Decidí inscribirme en el bootcamp Full Stack Web y fue la mejor decisión de mi vida. Ahora tengo la oportunidad de trabajar con importantes empresas y mis ingresos han mejorado significativamente ¡Gracias Dojofullstack, por brindarme una educación de calidad!
                                </p>
                            </div>
                            <div className="author-info">
                                {!langUS ? <h6><span>Victor</span> </h6>:  <h6><span>Christopher</span> - from Canada </h6>}
                            </div>
                        </div>
                    </TabPanel>
              
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>
                                Después de completar el bootcamp de Full Stack Web, gané 1600 dólares por primera vez. El material didáctico era tan práctico que pude aplicarlo de inmediato a mis proyectos y ver resultados tangibles ¡Fue una experiencia transformadora!
                                    </p>
                            </div>
                            <div className="author-info">
                                {!langUS ? <h6><span>Bladimir</span> </h6>:  <h6><span>Daniel</span> - Victor from México </h6>}
                            </div>
                        </div>
                    </TabPanel>

           

                    <TabList className="testimonial-thumb-wrapper">
                    <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_2.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_1.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_4.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_3.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                      
                    
                    </TabList>

                </Tabs>

{!langUS &&
<div className="slide-btn text-center mt-5">
    <a href='/testimonios' target='_blank'>
    <button value="" style={{textAlignLast: "center", fontSize: "15px"}} className="rn-button-style--2 btn-primary" >
<FcPodiumWithSpeaker style={{fontSize: "20px", marginRight: "10px"}}/>
     Ver más testimonios
</button>
    </a>

</div>}

            </div>
        </div>
    )
}

export default TestimonialOne
