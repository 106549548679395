import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TestimonialTree = ({langUS=false}) => {
    return (
        <div className="row">

            <div className="col-12">
                <Tabs>
                   
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>
                                {!langUS ? "“Cuando aparece un gran sueño frente a tus ojos, ¡atrápalo!”":
                                 "“When a big dream appears in front of your eyes, catch it!“"}
                                    </p>
                            </div>
                            <div className="author-info">
                                <h6><b>
                                    {!langUS ? "Fundadores de Google - Larry Page y Sergey Brin": "Founders of Google - Larry Page and Sergey Brin"}
                                    </b></h6>
                            </div>
                        </div>
                    </TabPanel>
        
                    <TabList className="testimonial-thumb-wrapper">
                     
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img style={{"transform": "scale(1.6)"}} width="90px" src="/assets/images/team/faces/founder_google.webp" alt="Fundadores google"/>
                                </div>
                            </div>
                        </Tab>
                       
                    </TabList>
                </Tabs>

             
            </div>
        </div>
    )
}

export default TestimonialTree
